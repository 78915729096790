<style lang="sass" scoped>
.container-login
  // background-color: #f8f9fa
  font-size: 12px

  .title
    font-weight: bold
    font-size: 1.5rem
    color: #555

</style>
<template lang="pug">
div.container-login
  .container
    .row.justify-content-center.align-items-center(style='height: 100vh')
      .col-xl-5.col-lg-6.col-md-7
        h3.title.pb-3 로그아웃 중 입니다.

</template>

<script>

export default {
  name: 'logout',
  components: {

  },
  data() {
    return {
      done: false,
    }
  },
  computed: {
    session() {
      return this.$store.state.session
    },
  },
  mounted() {
    // if (!this.session?.id) {
    //   if (window.analytics) {
    //     window.analytics.reset()
    //   }
    //   this.$modal.show('dialog', {
    //     title: '알림',
    //     text: '로그아웃 되었습니다.',
    //   })
    //   return this.$router.push({
    //     path: '/',
    //   })
    // }

    this.$store.dispatch('clear session')

    setTimeout(() => {
      this.$modal.show('dialog', {
        title: '알림',
        text: '로그아웃 되었습니다.',
      })
      this.$router.push({
        path: '/',
      })
    }, 1000);
  }
}
</script>
